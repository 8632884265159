
const params = new URLSearchParams(window.location.search);

let page_vars={};
for (const [key, value] of params.entries())
	page_vars[key]=value;

let page_lng="en";
let player=null;

function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline';
	});	
	page_lng=lng;
}
	
async function load_data(document,spreadsheet)
{
    const url = "https://sheets.googleapis.com/v4/spreadsheets/" + document + "/values/"+
                spreadsheet+"?alt=json"+"&key=AIzaSyD8c-oT4E9kvfAvrgUFOsm0b9wdDYOEI34";
    const res=await fetch(url);
    const data=await res.json();
    
    return(to_json(data.values));
}

function to_json(data)
{
    let res={};
    for(let a=1;a<data.length;a++)
    {
        let i=data[a][0].replace(/(\r\n|\n|\r)/gm,"").trim();
        d={};
        for(let b=1;b<data[0].length;b++)
        {
            d[data[0][b]]=data[a][b];
        }
        if(res[i])
        {
            if(!Array.isArray(res[i]))
            {
                const b=res[i];
                res[i]=[b];
            }
            res[i].push(d);
        }else   
            res[i]=d;
    }
    return(res);
}

function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

async function load()
{

/*	if (screen.orientation) { // Verifica si el objeto screen.orientation está disponible
		screen.orientation.lock("portrait").catch(function(error) {
		});
	  } else {
	  }
*/

	show_lng(page_lng)
	document.querySelectorAll(".btn_lng").forEach(element=>{
		element.addEventListener('click', lng_click,false);
	});

	if(document.getElementById("header"))
		document.getElementById("header").classList.remove("header_hide");	

	//screen.orientation.lock("portrait");

	document.querySelectorAll(".bio_lng a").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				show_lng(el.getAttribute("href"));

				document.querySelectorAll(".bio_lng a.active").forEach(prev=>{
					prev.classList.remove("active");
				});
				document.querySelectorAll(`.bio_lng a[href="${el.getAttribute("href")}"`).forEach(prev=>{
					prev.classList.add("active");
				});
			
				//el.parentNode.querySelector(".active").classList.remove("active");				
				//el.classList.add("active");
			});
		});
		
	
	document.querySelector(".hamb_image").addEventListener("click",e=>{
		//stop_interval(true);
		document.querySelector("nav").classList.remove("hide-menu");
		//document.getElementById("side-menu").checked^=true; 
		document.getElementById("side-menu").checked=true; 
		document.querySelectorAll(".item.active").forEach(el=>{
			el.classList.remove("active");
		});
		document.querySelectorAll(".nav_menu a.selected").forEach(el=>{
			el.classList.remove("selected");
		});
		document.querySelectorAll("[aria-section]").forEach(sec=>{
			if(sec.getAttribute("id")==="works")
				sec.classList.remove("hide");
			else 
				sec.classList.add("hide");
			});	
	});
	

	document.querySelectorAll(".nav_menu a").forEach(el=>{
		el.addEventListener("click",e=>{
			e.preventDefault();
		
			if(el.hasAttribute("hide-menu"))
			{
				document.querySelector("nav").classList.add("hide-menu");
				document.getElementById("side-menu").checked=false; 
				start_interval(document.getElementById("photography"));
			}else{
				document.querySelector("nav").classList.remove("hide-menu");
				document.getElementById("side-menu").checked=true; 
			}
			//	stop_interval(true);

			/*
			if(el.hasAttribute("aria-tag"))
			{
				if(!el.classList.contains("selected"))
				{
					document.querySelectorAll(`#works [aria-tag]`).forEach(it=>{
						it.classList.add("hide");
					});
					document.querySelectorAll(`#works [aria-tag='${el.getAttribute("aria-tag")}']`).forEach(it=>{
						it.classList.remove("hide");
					})
				}else{
					el.classList.remove("selected");
					document.querySelectorAll(`#works [aria-tag]`).forEach(it=>{
						it.classList.remove("hide");
					});
					return;
				}
			}
			*/

			let section=el.getAttribute("href");

			const prev=document.querySelector(".nav_menu a.selected");
			if(prev==el) return;
			
			if(prev)
				prev.classList.remove("selected");	
			
			if((prev!=el) || (!el.hasAttribute("aria-tag")))	
				el.classList.add("selected");
			

			if(el.hasAttribute("aria-tag"))
			{
				if(document.querySelector("[aria-tag].selected")==null) section="works";
				
				document.querySelectorAll(".item.active").forEach(el=>{
					el.classList.remove("active");
				});
			}
			document.querySelectorAll("[aria-section]").forEach(sec=>{
				if(sec.getAttribute("id")===section)
					sec.classList.remove("hide");
				else 
					sec.classList.add("hide");

			});
		});
	});
		
	const works=await load_data("1-PkgUM0wQmMxOlbHXd0gGnhSMP2ADVoKUs8Dy4WiX0Q","Works");
	if(works)
	{
		//console.log(works);	
		
		const all=document.getElementById("works");
		const slider=document.querySelector("#photography.slider").querySelector(".slider_images");
		const slider_bottom=document.querySelector(" #photography.slider").querySelector(".slider_bottom");
		for(w in works)
        {
			if(works[w].tag==="Photography")
			{
				const slide=document.querySelector("#elements .slide").cloneNode(true);
				slide.setAttribute("aria-slide",slider.childElementCount);
				slide.setAttribute("src",works[w]["url"]);	
				slider.append(slide);

				const btn=document.querySelector("#elements .slide_btn").cloneNode(true);
				btn.setAttribute("href",slider.childElementCount-1);
				slider_bottom.append(btn);
			}//else{
	            const item=document.querySelector("#elements .item").cloneNode(true);
    	        item.querySelector(".item_title").innerHTML=works[w]["title"];
        	    item.querySelector(".item_info").innerHTML=works[w]["info"];
				if(works[w]["credits"])
					item.querySelector(".item_credits").innerHTML=format_credits(works[w]["credits"]);
				else{
					item.querySelector(".item_credits").remove();
					item.querySelector(".item_link").removeAttribute("href");
				}
				item.setAttribute("aria-type",works[w].type);

				item.setAttribute("aria-tag",works[w].tag);

				if(works[w]["gallery"])
					item.setAttribute("aria-gallery","mix");
				
				if(works[w]["thumb"])
				{
					item.querySelector(".item_thumb").setAttribute("src",works[w]["thumb"]);
					item.querySelector(".item_loading").style.display="none";
					item.querySelector(".item_thumb").classList.remove("hide");
				}

				if(works[w]["preview"])
				{
					item.querySelector(".item_preview").setAttribute("src",
					//"https://ht-cdn.trafficjunky.net/uploaded_content/video/002/067/691/2067691_video.mp4");
					works[w]["preview"]);
				}else item.querySelector(".item_preview").remove();
				
				if(works[w]["url"])
				{
					if(works[w]["type"]==="picture")
						item.setAttribute("aria-src",works[w]["url"]);
					else
						item.setAttribute("aria-src",format_vimeo(works[w]["url"]));
				}
				let col=parseInt(all.getAttribute("aria-col"));
	            all.querySelector(".col_"+col).append(item);
				if(col==0)
				{
					const newitem=item.cloneNode(true);
					newitem.classList.add("mobile");
					all.querySelector(".col_1").append(newitem);
				}

				all.setAttribute("aria-col",col^1);

				if(works[w].tag!=="Photography")
				{
					const type=document.getElementById(works[w].tag.toLowerCase());
					col=parseInt(type.getAttribute("aria-col"));
	            	type.querySelector(".col_"+col).append(item.cloneNode(true));
					if(col==0)
					{
						const newitem=item.cloneNode(true);
						newitem.classList.add("mobile");
						type.querySelector(".col_1").append(newitem);
					}
					type.setAttribute("aria-col",col^1);
				}

				if(works[w].gallery)
				{
					const mix=works[w].gallery.split("*");
					const mix_slider=document.querySelector("#mix.slider").querySelector(".slider_images");
					const mix_slider_bottom=document.querySelector("#mix.slider").querySelector(".slider_bottom");
				
					for(m in mix)
					{
						const slide=document.querySelector("#elements .slide").cloneNode(true);
						slide.setAttribute("aria-slide",mix_slider.childElementCount);
						slide.setAttribute("src",mix[m]);	
						mix_slider.append(slide);

						const btn=document.querySelector("#elements .slide_btn").cloneNode(true);
						btn.setAttribute("href",mix_slider.childElementCount-1);
						mix_slider_bottom.append(btn);
					}
				}
        	//}
		}
		document.querySelectorAll(".grid .item_link").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				if(!el.parentNode.parentNode.querySelector(".item_credits"))
					return;
				if(!el.parentNode.parentNode.classList.contains("active"))
				{
					const prev=document.querySelector(".grid .item.active");
					if(prev)
						prev.classList.remove("active");					
				}	
				el.parentNode.parentNode.classList.toggle("active");
			});
		});

		document.querySelectorAll("[aria-type]").forEach(el=>{
			el.querySelector(".item_media").addEventListener("click",e=>{
				e.preventDefault();


				if(el.hasAttribute("aria-gallery"))
				{
					document.querySelectorAll("[aria-section]").forEach(sec=>{
						if(sec.getAttribute("id")==="mix")
							sec.classList.remove("hide");
						else 
							sec.classList.add("hide");
					});
					document.getElementById("side-menu").checked=false; 
					start_interval(document.getElementById("mix"));
					return;
				}

				const popup=document.getElementById(el.getAttribute("aria-type")+"_popup");;
				if(el.getAttribute("aria-type")=="picture")
				{
					popup.querySelector("img").setAttribute("src",el.getAttribute("aria-src"));
					popup.classList.remove("hide");
				}else{
					const iframe=popup.querySelector("iframe");
					iframe.onload=function(){
						
						player = new Vimeo.Player(iframe);
						player.play();
						popup.classList.remove("hide");
						/*
						if (iframe.requestFullscreen) {
							iframe.requestFullscreen();
						} else if (iframe.mozRequestFullScreen) { 
							iframe.mozRequestFullScreen();
						} else if (iframe.webkitRequestFullscreen) {
							iframe.webkitRequestFullscreen();
						} else if (iframe.msRequestFullscreen) { 
							iframe.msRequestFullscreen();
						}
						*/
					};
					iframe.setAttribute("src",el.getAttribute("aria-src"));
				}
				
			})
		});

		document.querySelectorAll('[aria-type="video"]').forEach(el=>
		{
			el.addEventListener("mouseenter",e=>{
				const preview=el.querySelector(".item_preview");
				if(!preview) return;

				const video=el.querySelector("video");
				/*const progress=el.querySelector(".video_progress");

				video.addEventListener('progress', function() {
					var buffered = video.buffered;
					var loaded = 0;
					if (buffered.length > 0) {
						loaded = (buffered.end(0) / video.duration) * 100;
					}
					progress.value=loaded;
					if(loaded<100)
						progress.classList.remove("hide");
					else{
						setTimeout(function(){
							progress.classList.add("hide");	
						},500);	
					}
				});
				video.addEventListener('canplaythrough', function() {
					progress.value=100;
					setTimeout(function(){
						progress.classList.add("hide");		
					},500);
				});
				progress.value=0;
				*/
				preview.classList.remove("hide");
				el.querySelector(".item_thumb").classList.add("hide");	
				el.querySelector("video").muted=true;	
				el.querySelector("video").play();
			});
			el.addEventListener("mouseleave",e=>{
				const preview=el.querySelector(".item_preview");
				if(!preview) return;
				preview.classList.add("hide");
				el.querySelector(".item_thumb").classList.remove("hide");	
				el.querySelector("video").pause();
			});
		});

		document.querySelectorAll(".btn_close_popup").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				document.getElementById(el.getAttribute("href")).classList.add("hide");
				if(player) player.pause();
			});
		});

		document.querySelectorAll(".btn_slider_popup").forEach(el=>{
			el.addEventListener("click",e=>{
				e.preventDefault();
				document.getElementById("side-menu").checked=true; 
				document.getElementById(el.getAttribute("href")).classList.add("hide");
				document.getElementById("works").classList.remove("hide");
				document.querySelector(".nav_menu .selected").classList.remove("selected");
			});
		});

		
		document.querySelectorAll(".slider").forEach(sl=>{
			const gallery=sl.querySelector(".slider_images");
			const bottom=sl.querySelector(".slider_bottom");
			gallery.firstElementChild.classList.add("active");
			bottom.firstElementChild.classList.add("active");
			
			bottom.querySelectorAll(".slide_btn").forEach(el=>{
				el.addEventListener("click",e=>{
					e.preventDefault();
					stop_interval();
					change_slider(el,sl);
					start_interval(sl);
				});
			});
			
			let timeout;
			gallery.addEventListener('scroll', () => {
				stop_interval();
				clearTimeout(timeout);
				timeout = setTimeout(function(){update_active_slider(gallery,bottom);}, 150); 
				start_interval(sl);
			});

		});
		
		//document.querySelector("nav").classList.remove("loading");

		window.addEventListener('orientationchange', adjustScroll);

	}
}


function update_active_slider(slider,navButtons) 
{
    const scrollX = slider.scrollLeft + slider.offsetWidth / 2;
    const slides = slider.querySelectorAll('img');
    let closestIndex = 0;
    let minDistance = Infinity;

    slides.forEach((slide, index) => {
        const slideCenter = slide.offsetLeft + slide.offsetWidth / 2;
        const distance = Math.abs(slideCenter - scrollX);
        if (distance < minDistance) {
            closestIndex = index;
            minDistance = distance;
        }
    });


	const prev=navButtons.querySelector(".slide_btn.active");
    prev.classList.remove("active");
    navButtons.childNodes[closestIndex].classList.add('active');
	
	adjust_slider_buttons(navButtons,prev,navButtons.childNodes[closestIndex],closestIndex);
}

function format_vimeo(url)
{
	return(url.replace("?share=copy","").replace("vimeo.com","player.vimeo.com/video"));
}

let timer=null;

function start_interval(sl)
{
	sl.classList.add("active");
	timer=setInterval(function(){
		const slider_bottom=sl.querySelector(".slider_bottom");
		let next=slider_bottom.querySelector(".slide_btn.active").nextElementSibling;
		if(!next)
		{
			next=slider_bottom.firstElementChild;
			slider_bottom.scrollTo(0,0);
		}
		change_slider(next,sl);
	},20000);
}
function stop_interval(restart=false)
{
	const sl=document.querySelector(".slider.active");
	if(!sl) return;
	sl.classList.remove("active");
	clearInterval(timer);
	if(restart)
	{
		//change_slider(sl.querySelector(".slider_bottom").firstElementChild,sl);
		/*
		setTimeout(function(){
			sl.querySelector(".slider_images").scrollTo(0,0);
			},250);
		const prev=sl.querySelector(".slider_bottom .active");
		if(prev)
			prev.classList.remove("active");
		sl.querySelector(".slider_bottom").firstElementChild.classList.add("active");		
		*/
	}
}
function is_in_viewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
function change_slider(el,sl)
{
	const slider=sl.querySelector(".slider_images");
	const bottom=sl.querySelector(".slider_bottom");
	const prev=sl.querySelector(".slider_bottom .active");
	if(prev)
		prev.classList.remove("active");
	el.classList.add("active");

	const nslide=parseInt(el.getAttribute("href"));

	slider.scrollTo(slider.childNodes[0].offsetWidth*nslide,0);

	adjust_slider_buttons(bottom,prev,el);	
}

function adjust_slider_buttons(bottom, prev, el, i=null)
{
	if(i!==null)
	{
		if(bottom.childNodes[i-1]) && (!is_in_viewport((bottom.childNodes[i-1])))
			bottom.scrollTo(bottom.childNodes[0].offsetWidth*(i-1),0);
		return;
	}

	if(parseInt(prev.getAttribute("href"))<parseInt(el.getAttribute("href")))
	{
		const next=el.nextElementSibling;
		if(next)
			if(!is_in_viewport(next))
			{
				bottom.scrollBy(bottom.childNodes[0].offsetWidth,0);
			}
	}else{
		const prev=el.previousElementSibling;
		if(prev)
			if(!is_in_viewport(prev))
			{
				bottom.scrollBy(-bottom.childNodes[0].offsetWidth,0);
			}
	}
}

function adjustScroll()
{
	document.querySelectorAll(".slider").forEach(el=>
	{
		const slider=el.querySelector(".slider_images");
		setTimeout(function(){
			slider.scrollTo(0,0);
			const bottom=el.querySelector(".slider_bottom");
			bottom.querySelector(".active").classList.remove("active");
			bottom.childNodes[0].classList.add("active");
			bottom.childNodes[0].scrollIntoView();
		},250);	
	});
}

function format_credits(credits)
{
	const row=document.querySelector("#elements .credits_row").cloneNode(true);
	const items=credits.split("*");
	let col=0;
	for(i of items)
	{
		const credit=document.querySelector("#elements .credit").cloneNode(true);
		const data=i.split("/");
		credit.querySelector(".title").innerHTML=data[0];
		credit.querySelector(".name").innerHTML=data[1];
		row.querySelector(".column_"+col).append(credit);
		col^=1;
	}
	//console.log();
	return(row.outerHTML);
}

document.addEventListener("DOMContentLoaded", load, false);

